@import "sass-lib";
@media (min-width: 0px) and (max-width: 1280px) {
	.wrapper{ width: 784px; }
	.ttl-content{ padding-bottom: 20px; }
	header{
		&.no-login {
			&:before {width: 28.5%;}
		}
		&:before{ width:38%; }
		.wrapper{ margin: 0px 70px 0px 70px; }
		.nav-burgermenu{
			.in-nav-burgermenu{
					width: 50%; padding-bottom: 40px; overflow:unset; height: auto;
				&.corporate{
					padding: 40px 0px 30px; width: 33.333%;
					.roundedlink{
						a{ 
						 	margin-bottom: 30px; 
						 	&:last-child{ margin-bottom:0; }

						}
					}
					.menu{
						> li{ 
							font-size: 18px; padding: 5px 40px; margin-bottom: 30px; line-height: 24px;
							.drop-burger{
								> ul{
									padding: 40px 0px;
									li{ padding: 0px 40px; margin-bottom: 30px; font-size: 18px; }
								}
							}
						}

					}
					
				}
			}
			.roundedlink{
				a{ 	margin-bottom: 0; }
			}
			.foot-head{
				.sosmedhead{
					a{ margin-left: 5px; }
				}
			}
			.searchbox{
				margin-bottom: 30px;
				input[type=text]{ height: 55px; }
			}
		}
	}
	.banner-home{
		height: calc(100vh - 120px);
		&.consumer{ height: calc(100vh - 120px);}
		.tabing{ 
			width: auto; left: 10%; right: 10%; bottom: 5%; 
			ol{
				li{ 
					margin: 0px 10px 0px 0px; 
					&:last-child{ margin-right:0px; }
				}
			}
		}
		.text{ top: 40%; }
		.slider-small{ display: none; }

	}
	.home{
		h2{ font-size: 28px; line-height: 33px;}
		h3{ font-size: 18px; }
		&.services{
			&:after{ height:160px; }
			.services-list{
				.ls-services{
					.desc{
						h5{ font-size: 13px; line-height: 20px; }
						&:before{ width:480px; height: 335px; }
					}
				}
			}
		}
		&.calculator{
			padding: 10px 0px; height: 120px;
			&:after{ width:90%; }
			&:before{ width:110px; background-position: left; }
			h4{ font-size: 14px; line-height: 24px; padding-right: 0px; }
			.wrapper{ margin:0px 20px 0 80px; }
			.calculator-right{ 
				padding-left: 30px; vertical-align: middle;
				h4{
					padding: 0px 0px 0px 0px; display: block; margin-bottom: 5px;
				}
				a{ position: relative; display: inline-block; top: auto; @include transform(translateY(0)); width: auto; }
			}
			.calculator-left{ 
				padding-left: 15px; padding-right: 15px; vertical-align: top; width: 70%;
				h3{ float: none;  width: 100%; margin-bottom: 5px; }
				.form-calculator{ float: none; width: 100%; }
			}
			.btn.rounded{ line-height: 40px; padding: 0px 20px; font-size: 14px; }
			
			
		}
		&.testimonial{
			.slider-testi{
				.qts{
					p{ height: 76px; overflow: hidden; }
				}
			}
		}
	}
	.form-calculator{
		&.fixed{
			.bottom-form-calculator{
				>div{ width: 150px; }
			}
		}
		.bottom-form-calculator{ 
			height: 40px; width: 100%;
			input[type='text'],input[type=number], select{ height: 40px; font-size: 12px; }
			button{ 
				top: 2px; bottom:2px; width: 110px; right: 4px; height: 35px; font-size: 12px;
				&:before{ left:10%; @include transform(scale(.8));}
				&:hover{
					font-size: 12px;
				}
			}
			select{ width: 100%; }
			input[type='text']{width:80px; font-size: 12px; }
		}
	}
	.top-form-calculator{
		padding: 0px 0px 10px 0px;
		label{ margin-right: 30px; font-size: 12px; }
	}
	
	.calculator-foot{
		.lf-calculator{
			padding-right: 25%;
			h3{ font-size: 20px;   }
			p{ 
				padding-left: 0px; padding-right: 20px; width: 50%; text-align: left;
				&:before{ left:auto; right: 0; }
			}
			.btn-simulasi{ 
				padding-right: 40px; 
				h3{ 
					display: block; 
					&:after{ width:20px; background-size: 100%;}
				}
			}
			
		}
		.bottom-form-calculator select{ background-size: 4%; }
		.btn{ padding: 0 20px; }
	}
	.wrap-product{
		h6{ font-size: 11px; }
		.ls-product{ 
			width: calc(33.333% - (30px / 3)); margin: 0 15px 30px 0; 
			&:nth-child(4n){ margin-right: 15px; }
			&:nth-child(3n){ margin-right: 0; }
		}
		.price{ font-size: 18px; }
		.tdp-price{ font-size: 11px; }
	}
	

	.t-center{ text-align: left !important; }
	.bottom-form-calculator{
		input[type='text'], input[type=number], select{ font-size: 14px; padding: 0px 10px; }
		select{ background-position: 95% center; padding-right: 10px; background-size: 10%; }
	}
	.middle{
		.wrapper{ position: relative; }
		aside{ 
			width: auto; position: absolute; top: -120px; left: 0; right: 0;  z-index: 2; float: none;
			.navigasi{ 
				display: none; max-height: none !important; min-height: 0 !important; 
				*{ max-height: none !important; }
			}
			.tittle{ 
				right: 0; padding: 30px 160px 30px 0; text-align: left; 
				.sub-trgr{
					text-align: right; position: absolute; right: 10px; top: 0; bottom: 0; margin: auto 0;
					line-height: 84px; font-size: 16px; color:#AAE360; padding-right: 20px;
					cursor: pointer; width: 190px;  display: -webkit-box;
					  -webkit-box-orient: vertical;
					  -webkit-line-clamp: 1;
					  overflow: hidden;
					&:after{ 
						content:''; position: absolute; right: 0; top: 0; bottom: 0; width:12px; height: 8px;  
						background: url(../images/material/arr-wht-dwn.png)no-repeat center; margin: auto 0;
						@include transition(.2s all $ease-out);
					}
					&.slidedown{
						&:after{ @include transform(rotate(-180deg));}
					}
				}
			}
			ul{
				li{ 
					text-align: left; padding-left: 0;
					&.have-child{
						> a{
							&:before{ left:auto; right: -20px; }
						}
						> ul{
							padding-left: 20px;
							li{
								&.sub-child{
									a{
										&:before{ left:auto; right: -20px; }
									}
									&.action{
										> a{
											&:before{ left:auto; right: -20px; }
										}
									}
									ul{
										padding-left: 20px;
										li{
											a{
												&:before{ left:auto; right: 0; }
											}
										}
									}
								}
							}
						}
					}

				}
			}
		}
	}
	.tittle-middle{
		padding-bottom: 20px;
		&:after{ width:60px; }
		h2{ font-size: 24px; line-height: 34px; }
		h5{ font-size: 18px; line-height: 28px; }
	}
	.login-home{
		.text{
			h2{ font-size: 28px; line-height: 38px; }
			p{ font-size: 18px; }
		}
		.form-login{ width: 384px; }
	}
	footer{
		.foot-top{
			.form-newsletter{ width: 305px;	 }
			.foot-top-left{
				a{ margin: 0 5px; }
			}
			.foot-download{
				@include afterclear; width: 100%;
				a{ 
					width: 49%; display: inline-block;  float: left; margin-right: 2%;
					img{ width: 100%; }
					&:last-child{ margin-right: 0;}
				}
			}
		}
		.foot-bottom{
			.foot-bottom-left{ float: none; display: block; width: 100%; text-align: center; margin-bottom: 10px;}
			.foot-bottom-right{ float: none; display: block; width: 100%;  text-align: center;}
		}
	}
}

@media (min-width: 0px) and (max-width: 1023px) {
	.wrapper{ 
		width: 584px; 
		&.v2{ width: 584px;  }
	}
	.backto{ font-size: 14px; line-height: 24px; }
	h2{ font-size: 20px; }
	.btn{
		&.normal{ line-height: 44px; }
	}
	header{
		height: 80px;
		&.no-login {
			&:before {width: 34%;}
		}
		&:before{ width:45%; height: 80px; }
		.wrapper{
			margin: 0px 45px 0px 45px;
		}
		.right-header{ padding-top: 20px; }
		.logo{ padding-top: 10px; }
		.nav-burgermenu{
			.in-nav-burgermenu{ 
				width: 70%;overflow: auto;
				.menu{
					> li{ font-size: 20px; }
				}
				&.corporate{
					width: 52%;
					.roundedlink{
						a{ margin-bottom: 20px; }
					}
					.menu{
						> li{ 
							margin-bottom: 20px; 
							.drop-burger{ 
								right: 52%; width: 45%; background: rgba(17,44,80,.97);
								&:before{ display:none; }
								> ul{
									height: calc(100vh - 80px); overflow:auto; position: relative;
									li{ 
										font-size: 16px; margin-bottom: 20px;
										.sub-drop-burger{
											position: relative; right: 0; width: 100%; opacity: 1; visibility: visible;
											z-index: 5;  padding: 15px 0px; display: none;
											@include transition(none);
											&:before{ background: transparent; }
											>ul{
												li{
													padding: 5px 0px 5px 15px; margin-bottom: 5px;
													a{ color: rgba(255,255,255,0.7); }
												}
											}
										}
										&:hover{
											&:before{ bottom:auto; height: 22px; }
										}
									}
								}
							}
						}
					}

				}
			}
			.roundedlink{
				display: flex;
				.tosite{ margin-right: 20px; }
			}
		}

	}
	.banner-home{
		.text{
			h2{ font-size: 32px;line-height: 42px;}
			p{ font-size: 14px; line-height: 24px; }
		}
		.tabing{ 
			left: 30px; right:30px;  text-align: center; width: auto;
		}
	}
	.banner-middle{
		figure{ height: 360px; }
		&.banner-detail{
			figure{ 
				padding-top: 80px; height: 180px;
				.tittle-banner-detail{
					top: 60%;
					.date{ font-size: 12px; margin-bottom: 5px; }
					h5{ font-size: 16px; line-height: 26px; }
				}
			}
		}
	}
	.home{
		padding: 50px 0px;
		h2{ font-size: 20px; line-height: 30px; }
		h3{ line-height: 28px; }
		&.calculator{
			height: 120px; padding-top: 10px;

			h3{ margin-bottom: 5px; }
			h4{ font-size: 12px; line-height: 22px;margin-bottom: 10px; }
			.wrapper{ width: auto; margin: 0px 20px 0 60px; }
			&:before{ background-size: 100% 100%; width: 265px;}
			.calculator-right{ padding-left: 15px; }
			.calculator-left,.calculator-right{ vertical-align: top; }
			.btn.rounded{ font-size: 12px; }
			.form-calculator{
				.bottom-form-calculator{ 
					height: 40px; padding-left: 5px; 
					input[type=number],select{ height: 40px; width: 110px; padding: 0px 15px 0px 5px; }
					input[type='text']{ width: 150px; height: 40px; }
				

				}

			}
			.top-form-calculator{ 
				padding: 0px 0px 5px 15px; 
				label{ margin-right: 25px; }
			}
			.bottom-form-calculator{
				button{ height: 30px; top: 6px; bottom: 6px; font-size: 12px; width: 90px; right: 4px;}
			}

		}
		&.aboutus{
			.wrap-left-right{
				.right{ padding-left: 25px; width: 50%; }
			}
			.std-content{
				p{ max-height: 100px; overflow: hidden; }
			}
			
		}
		&.testimonial{
			.slider-testi{
				padding-bottom: 40px;
				.qts{
					p{ padding-top: 15px; }
				}
				.list-slider{
					padding: 20px 25px;
				}
			}
		}
		&.services{
			overflow: hidden;
			.services-list{
				margin-right: -1000px;
				.ls-services{ margin: 0px 15px 0px 0px; width: 300px; }
			}
		}
	}
	.ttl-content{
		padding-bottom: 15px; margin-bottom: 30px;
		&:after{ width:40px; left: -20px; }
	}
	.share-medsos{ display: none; }
	.partner{
		.sliderpartner{
			width: 67%;
			.ls-partner{ padding: 0 20px; }
		}
		.tab-partner{
			.btn-tab-partner{
				height: 80px; width: 175px;
				a{
					width: 65px; height: 65px; margin-top: 5px;
					> span{ font-size: 7px; }
				}
			}
		}
	}
	.wrap-product{
		h5{ font-size: 14px;  }
		h6{ margin-bottom: 5px; }
		.desc{ padding: 15px; }
		.price{ font-size: 14px; }
	}
	.calculator-foot{
		.lf-calculator{
			padding-right: 30%;
			h3{ line-height: 26px; font-size: 16px; }
			.btn-simulasi{ width: 125px; padding-right: 25px; }
		}
		.btn{ width:33%; font-size: 12px;  }
	}

	.bottom-form-calculator{ padding-left: 5px; }
	.form-calculator{
		&.fixed{
			.bottom-form-calculator{
				> div{ width: 140px; }
			}
		}
	}
	.middle{
		.wrapper{ width: 584px; }
		aside{
			.tittle{ 
				left: 25px;  font-size: 18px;
				&:before{ right:99%; z-index: -1; }
			}
			.navigasi{
				padding: 90px 0px 50px 30px;
			}
		}
	}
	.content-middle{ width: auto; float: none;}
	.twobox-middle{
		.rounded-desc{
			bottom: 15px;
			h5{ font-size: 15px; margin-bottom: 5px; }
		}
	}
	.img-middle{
		.desc{ 
			width: 302px; padding: 20px 25px;
			h3{ font-size:14px; line-height: 24px;  }
		}
	}
	.std-content{
		ul{
			li{ 
				font-size: 14px; line-height: 24px; 
				&:before{ top:5px; }
			}
		}
		p{ font-size: 14px; line-height: 24px; }
		.linkto{ font-size: 14px; line-height: 24px; }
	}
	.twobox-middle{
		.rounded-desc{
			&:before{ width:400px; left: -150px; height: 275px; bottom: -220px }
			.text-hover{
				p{ max-height: 100px; overflow:hidden; font-size: 14px; line-height: 24px; }
			}
		}
		.box{
			&:hover{
				.rounded-desc{
					&:before{ }
					.text-hover{
						height: 100px;
					}
				}
			}
		}
	}
	.twobox-bg{
		.text{ 
			padding: 0px 20px; 
			h6{ font-size: 14px; }
			h5{ font-size: 16px; }
			p{ font-size: 14px;  }
		}
		.list-box{ 
			padding:0px 20px 20px 20px; 
			&:before{ height:95%; }
		}
		&.career-slider{
			padding-top: 30px;
			.list-box{ 
				padding:40px 20px 20px 20px; margin: 0 5px 20px;
				&:before{ height:90%; }
			}
			figure{
				height: 200px; display: block; margin-top: -30px;
				img{ height: 100%; width: 100%; object-fit: cover; }
			}
		}

	}
	.visimisi{
		h5{ font-size: 14px; line-height: 24px; margin-bottom: 5px; }
		p{ font-size: 14px; line-height: 24px; }
		.tittle-value{ font-size: 14px; line-height: 24px; }
		&.block{
			.text{
				&.visi{
					p{ font-size: 18px; line-height: 28px; }
				}
			}
		}
	}
	.tittle-middle{
		h2{ font-size: 20px; line-height: 30px; }
		h5{ margin-bottom: 10px; }
	}
	.wrap-pop{
		.pop-inner{
			.content-pop{ 
				width: auto; margin: 15% 40px; 
				padding: 35px 15px;
			}
		}
	}
	.result-simulasi{
		.row-btn{
			.left{ width: 40%; }
			.ic{ 
				padding-right: 40px; margin-right: 5px;
				&:after{ right:10px; }
			}
			.right{
				a{ margin-right: 5px; }
			}
		}
	}
	.wrap-address{
		h5{ font-size: 16px; line-height: 26px; margin-bottom: 5px; }
		p{ font-size: 14px; line-height: 24px; }
	}
	.tittle-form{ line-height: 55px; font-size: 13px; }
	.tittle-contactus{ padding: 10px 60px 0px 0px; }
	.sec-form{
		.option{ 
			margin-bottom: 5px; font-size: 14px; 
			position: relative;padding-left: 30px; box-sizing: border-box;
			line-height: 24px;
			input[type=radio]{
				position: absolute;left: 0; top: 5px; bottom: 0; 
			}
		}
		@include placeholder{ font-size: 14px; }
		input[type='text'], input[type='number'],  input[type='email'], input[type='file'],select{ font-size: 14px; height: 44px; padding: 0px 20px; }
		textarea{ font-size: 14px; padding: 15px 20px;   }
		&.nobanner{
			.content-form{ padding-top: 100px; }
		}
	}
	.g-option{
		.option {margin-bottom: 15px;}
	}
	.promo-list{
		.list{ min-height: auto; height: 230px; }
		.text{ width: 60%; padding: 30px 20px; }
		figure{ width: 40%; }
		.date{ font-size: 14px; }
		h5{ font-size: 14px; line-height: 24px; }
		p{ font-size: 14px; line-height: 24px; }
	}
	.award{
		margin: 0 -10px; 
		.list-award{
			padding: 0px 10px;
			.text{
				p{ display: none; }
				h5{ font-size: 14px; }
				h6{ font-size: 14px; }
			}
		}
	}

	.twobox-list{
		h5{ font-size: 16px; line-height: 26px; }
		figure{
			img{ width: 100%; height: 100%; object-fit: cover; }
		}
		p{ font-size: 14px; }
		.date{ font-size: 12px; right: 10px; padding: 10px 20px 10px 30px; }
	}
	.milestone-slider{

		.list{
			padding: 70px 0px;
			&:before{ width:95px; height:80px; background-size: 100% !important; left: 32px;bottom: 32px;}
			&:nth-child(even){
				&:before{top:32px;}
			}
			.desc{ top: 20px; }
		 	figure{ width: 122px; height: 122px; background-size: cover; padding: 20px; }
		}
	}
	.sec-form{
		.content-form{ padding: 35px 45px; }
		@include placeholder{ font-size: 14px; }
	}

	.table-middle{
		.t-right{ text-align: left !important; }
		tbody{
			td{ vertical-align: top; }
		}
		&.corpo{
			thead{
				th{ font-size: 14px; padding: 20px 15px; line-height: 24px; }
			}
			tbody{
				td{ font-size: 14px; padding: 20px 15px; }
			}
		}
	}
	.wrap-acc-middle{
		min-height: unset;
		.grid-acc{
			h5{ font-size: 16px; line-height: 26px; }
		}
	 	.content-acc{
			h6{ font-size: 14px; line-height: 24px; }
			p{ font-size: 14px; line-height: 24px; }
	
		}
	}
	.related-news{
		margin: 100px -5px 0;
		.list-news{ 
			width: 50%; padding: 0 5px;  
			&:last-child{ display:none; }
		}
	}
	.detail-page{
		min-height: unset;
		.det-left{ width: 50%; height: 284px; }
		.det-right{ width:50%; height: 284px; padding: 25px 40px; }
		.slider-figure-detail{ height: 100%; }
		h3{ font-size: 20px; line-height: 30px; }
		h4{
			font-size: 16px; line-height: 26px; margin-bottom: 10px; padding-bottom: 10px; 
			&:before{ width:60px; }
		}
		.row{
			span{ margin-bottom: 10px; }
			.price{ display: block; margin-left: 0; }
		}
		.btn{ margin-top: 20px; }
	}
	.feature{
		.list-feat{ width: 25%; padding: 0 30px; }
	}
	.calldealer{ padding: 20px 35px; }
	.wrap-product{
		&.three{
			.ls-product{
				width: calc(50% - (30px / 2));
				&:nth-child(3n){ display: none; }
			}
		}
	}
	.sec-bggrey{ margin-bottom: 20px; }
	.login-home{

		.form{
			input[type='text'],  input[type='password'], input[type='email']{
				height: 40px; font-size: 16px; padding: 0 20px;
			}
			input[type='submit']{  height: 40px; font-size: 14px; }
			.row{
				.check{
					span{ font-size: 14px; }
				}
				.right{
					a{ font-size: 14px; }
				}
			}
			@include placeholder{ font-size: 14px; }
		}
		.form-login{
			top: auto; bottom: 20%; width: 284px; padding: 70px 20px 20px; right: 4%; @include transform(translateY(0));  
			p{ font-size: 12px; line-height: 22px;}
		}
		.logo,.text{ left: 4%; }
		.text{
			p{ font-size: 14px; line-height: 24px; }
		}
	}
	.form-calculator{
		&.fixed{
			div{
				&:nth-child(2){
					width: 110px;
					select{ width: 100%; }
				}
			}
		}
	}
	.page100vh{
		.wrap{
			.text{
				left: 5%; right: 5%;
				h2{ font-size: 120px; }
				h5{ font-size: 24px; margin-bottom: 20px; }
				&.under{
					h2{ font-size: 80px; }
				}
			}
		}
	}


	.content-middle .abs-all {font-size: 12px; top: 6px;}
	.list-job {
		.item {
			width: 50%;
			a {}
			.dte {font-size: 13px;}
		}
	}
	footer{
		.foot-top{
			.form-newsletter{
				input[type=email]{ height: 50px; font-size: 14px; }
				@include placeholder{ font-size: 14px; }
				input[type=submit]{ font-size: 14px; }
			}
			.foot-top-left{
				width: 55%;
				.box-foot{
					text-align: center;
					a{ float: none; margin: 20px 10px 0px; text-align: center; display: inline-block;}
				}
			}
			.foot-top-right{ width: 45%; }
			
		}
		.foot-bottom{
			.foot-bottom-right{
				a{ margin-bottom: 5px; }
			}
		}

	}
}